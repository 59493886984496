// 引入工具函数
import {
  http,
  httpimg
} from '../../api/index'

// 获取交通工具
export const gettrafficway = params => http.get('/ohealth/api/v1/system/dictdata/type/transportation_way', params)

// 待办任务-核酸症状-列表
export const todolistcovidlist = params => http.get('/ohealth/api/v1/isolation/todolistcovid/list', params)

// 待办任务-抗体检测-列表
export const todoListAntibody = params => http.get('/ohealth/api/v1/isolation/todolistantibody/list', params)

// 待办任务-体温症状-列表
export const todolisttemplist = params => http.get('/ohealth/api/v1/isolation/todolisttemp/list', params)

// 待办任务-解除列表
export const todolistdeisolationlist = params => http.get('/ohealth/api/v1/isolation/todolistdeisolation/list', params)

// 待办任务数量
export const taskTotalNumberApi = params => http.post('/ohealth/api/v1/isolation/resident/taskTotalNumber', params)

// 待办任务-添加采样计划时间
export const addRegLiveTask = params => http.post('/ohealth/api/v1/isolation/todolistcovid/addRegLiveTask', params)

// 预解除隔离后 解除隔离时数据回显
export const getRegLiveInfoApi = params => http.get('/ohealth/api/v1/isolation/reg/getRegLiveInfo', params)

// 待办任务-核酸检测 导出
export const exportCovidTaskApi = params => httpimg.post('/ohealth/api/v1/isolation/todolistcovid/exportCovidTask', params)

// 待办任务-抗体检测 导出
export const exportAntibodyTodoTaskApi = params => httpimg.post('/ohealth/api/v1/isolation/todolistantibody/exportAntibodyTodoTask', params)

// 待办任务-采集体温症状 导出
export const exportTempTodoTaskApi = params => httpimg.post('/ohealth/api/v1/isolation/todolisttemp/exportTempTodoTask', params)

// 待办任务-解除隔离/解除隔离审核 导出
export const exportRelieveTodoTaskApi = params => httpimg.post('/ohealth/api/v1/isolation/todolistdeisolation/exportRelieveTodoTask', params)

// 解除隔离审核 待办任务列表 (管理员有权限)
export const applyListApi = params => http.get('/ohealth/api/v1/isolation/todolistdeisolation/applyList', params)

// 解除隔离审核(通过/拒绝)
export const auditRelieveIsolateApi = params => http.post('/ohealth/api/v1/isolation/resident/auditRelieveIsolate', params)

//管理员批量审核预解除申请
export const auditBatchRelieveIsolateApi = params=>http.post('/ohealth/api/v1/isolation/resident/auditBatchRelieveIsolate',params)

// 查询待转运人员列表
export const getWaitTransportPersonList = params => http.get('/ohealth/api/v1/epidemicControl/transportreg/getWaitTransportPersonList', params)

// 取消转运登记
export const cancelTransportRegById = params => http.post('/ohealth/api/v1/epidemicControl/transportreg/cancelTransportRegById', params)

// 查询机构下所有人员（标识是否值班/排班信息）
export const getAllUserScheduling = params => http.get('/ohealth/api/v1/isolation/scheduling/getAllUserScheduling', params)

// 获取司机值班人员信息
export const getDriverDuty = params => http.get('/ohealth/api/v1/isolation/scheduling/getDriverDuty', params)

// 自动分配任务
export const autoInitTransportRegTask = params => http.post('/ohealth/api/v1/epidemicControl/transportreg/autoInitTransportRegTask', params)

// 手动分配任务
export const createTransportTask = params => http.post('/ohealth/api/v1/epidemicControl/transportreg/createTransportTask', params)

// 查询待处理任务
export const getWaitTaskList = params => http.get('/ohealth/api/v1/epidemicControl/transporttask/getWaitTaskList', params)

// 根据任务ID查询人员列表
export const getTransportRegTaskListByTaskId = params => http.get('/ohealth/api/v1/epidemicControl/transportreg/getTransportRegTaskListByTaskId', params)
// 重新分配任务
export const updateTransportTask = params => http.post('/ohealth/api/v1/epidemicControl/transportreg/updateTransportTask', params)

// 获取列表内容
export const getTransportRegLitPage = params => http.post('/ohealth/api/v1/epidemicControl/transportreg/getTransportRegPage', params)

// 重新转运
export const againTransportReg = params => http.post('/ohealth/api/v1/epidemicControl/transportreg/againTransportReg', params)

// 导出
export const exportTransportRegList = params => httpimg.post('/ohealth/api/v1/epidemicControl/transportreg/exportTransportRegList', params)

// 取消任务
export const cancelTaskById = params => http.get('/ohealth/api/v1/epidemicControl/transporttask/cancelTaskById', params)

// 取消人员任务
export const cancelSurveyTaskById = params => http.get('/ohealth/api/v1/epidemicControl/transporttask/cancelSurveyTaskById', params)

//重新分配获取以前的分配信息
export const transportTaskInfoApi = params=>http.get('/ohealth/api/v1/epidemicControl/transporttask/info',params)

//管控列表
export const gklist = params=>http.get('/ohealth/api/v1/epidemicControl/homereg/list',params)

// 解除隔离
export const relive = params => http.post('/ohealth/api/v1/epidemicControl/homereg/relive', params)

// 待确认列表
export const waitList = params => http.get('/ohealth/api/v1/epidemicControl/homereg/waitList', params)

// 导出未确认
export const exportWaitList = params => httpimg.get('/ohealth/api/v1/epidemicControl/homereg/exportWaitList', params)

// 修改社区
export const updateOrg = params => http.post('/ohealth/api/v1/epidemicControl/homereg/updateOrg', params)

// 确认居家隔离
export const confirm = params => http.post('/ohealth/api/v1/epidemicControl/homereg/confirm', params)

// 删除
export const deleteid = params => http.delete('/ohealth/api/v1/epidemicControl/homereg/delete/' + params.params.id, params)

/* 未确认人员列表 打印报告书 */
export const homeRegDownloadEntryBook = params => httpimg.get('/ohealth/api/v1/epidemicControl/homereg/downloadEntryBook', params)

// 居家隔离核酸检测列表
export const homeList = params => http.get('/ohealth/api/v1/isolation/todolistcovid/homeList', params)

// 导出待办任务-核酸检测
export const exportCovidTask = params => httpimg.post('/ohealth/api/v1/isolation/todolistcovid/exportHomeCovidTask', params)

// 居家隔离-待办解除隔离列表
export const toduHomeDeisolationList = params => http.get('/ohealth/api/v1/isolation/todolistdeisolation/toduHomeDeisolationList', params)

// 居家隔离-居家隔离管控统计
export const findHomeRegStatistics = params => http.get('/ohealth/api/v1/isolation/statistic/findHomeRegStatistics', params)

// 居家隔离管控统计-导出
export const exportHomeRegStatisticsList = params => httpimg.post('/ohealth/api/v1/isolation/statistic/exportHomeRegStatisticsList', params)

// 录入核酸
export const collectDetect = params => http.post('/ohealth/api/v1/isolation/resident/collectDetect', params)
